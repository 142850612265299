import { internalComAxios } from './axiosConfig';

// 同步
export function apiInternalSyncOrder (params: any) {
  return internalComAxios('/orderlist/sync/syncOrder', params, true, true);
}
// 补单
export function apiInternalRepairOrder (params: any) {
  return internalComAxios('/orderlist/sync/repairOrder', params, true, true);
}

// 小票查询
export function apiInternalGetRecords (params: any) {
  return internalComAxios('/orderlist/appstore/records', params);
}

// 解绑邮箱
export function apiInternalUnbindEmail (params: any) {
  return internalComAxios('cs/unbindEmail', params, true, true);
}

// 客服工具重置密码
export function apiInternalResetPwd (params: any) {
  return internalComAxios('cs/resetUserPwd', params);
}

// 重置实名认证
export function apiInternalResetIdCard (params: any) {
  return internalComAxios('cs/resetIdCard', params, true, true);
}

// 身份证实名认证清除
export function apiInternalCleanIdCardCount (params: any) {
  return internalComAxios('cs/account/cleanIdcardIdValidCount', params, true, true);
}

// 重置获取短信验证码次数
export function apiInternalResetSmsCount (params: any) {
  return internalComAxios('cs/resetSmsCount', params, true, true);
}

// 手机号登录缓存
export function apiInternalCleanPhoneToken (params: any) {
  return internalComAxios('cs/account/cleanPhoneToken', params, true, true);
}

// 注销第三方账号
export function apiInternalDelThirdAccount (params: any) {
  return internalComAxios('cs/account/bakUser', params, true, true);
}

// 国内退款订单列表
export function apiInternalRefundOrderList (params: any) {
  return internalComAxios('/orderlist/appstore/refundRecords', params);
}

// 国内基础接口
export function apiUsdkProjectList (params: any) {
	return internalComAxios('/public/projectList', params);
}

// 支付方式列表
export function apiInternalGetPayTypes (params: any) {
  return internalComAxios('/orderlist/payTypes', params);
}

// 补单日志列表
export function apiInternalReplaceLogs (params: any) {
  return internalComAxios('/orderlist/replace/logs', params);
}

// 修改实名认证信息
export function apiInternalChangeIdCard (params: any) {
  return internalComAxios('cs/changeIdCard', params, true, true);
}