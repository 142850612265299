import { apiInternalResetSmsCount, apiInternalCleanPhoneToken, apiInternalResetIdCard, apiInternalChangeIdCard } from '@/api/customerInternal'; //apiInternalCleanIdCardCount apiInternalDelThirdAccount
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/modules/app';
export const UseAccountService = () => {
  const {
    gameInfo
  } = storeToRefs(useAppStore());
  const paramConfig = ref([
  // {
  //   key: 'idCard',
  //   api: apiInternalCleanIdCardCount,
  //   params: [
  //     {
  //       label: '身份证号',
  //       prop: 'idCard',
  //       idCard: '',
  //       placeholder: '请输入身份证号',
  //       required: true,
  //     },
  //   ]
  // },
  {
    key: 'resetIdForm',
    api: apiInternalResetIdCard,
    params: [{
      label: '登录名',
      prop: 'loginName',
      loginName: '',
      placeholder: '请输入设备号',
      required: true
    }]
  }, {
    key: 'loginCacheForm',
    api: apiInternalCleanPhoneToken,
    params: [{
      label: '登录名',
      prop: 'loginName',
      loginName: '',
      placeholder: '请输入登录名',
      required: true
    }]
  },
  // {
  //   key: 'delThirdForm',
  //   api: apiInternalDelThirdAccount,
  //   params: [
  //     {
  //       label: '登录名',
  //       prop: 'loginName',
  //       loginName: '',
  //       placeholder: '请输入登录名',
  //       required: true,
  //     },
  //   ]
  // },
  {
    key: 'resetSmsForm',
    api: apiInternalResetSmsCount,
    params: [{
      label: '手机号',
      prop: 'tel',
      tel: '',
      placeholder: '请输入手机号',
      required: true
    }]
  }, {
    key: 'changeIdForm',
    api: apiInternalChangeIdCard,
    params: [{
      label: '登录名',
      prop: 'loginName',
      loginName: '',
      placeholder: '请输入登录名',
      required: true
    }, {
      label: '姓名',
      prop: 'realName',
      realName: '',
      placeholder: '请输入欲更换实名姓名',
      required: true
    }, {
      label: '身份证',
      prop: 'idCard',
      idCard: '',
      placeholder: '请输入欲更换实名身份证号',
      required: true
    }],
    botTips: ['若需测试不同年龄用户的游戏表现，请以当前日期为基准，模拟未成年人身份证号', '举例：当前日期：2023/07/13，则 8周岁为：xxxxxx20150713xxxx；16周岁为：xxxxxx20070713xxxx；18周岁为：xxxxxx20050713xxxx']
  }]);
  const toolsList = [
  // {
  //   key: 'idCard',
  //   label: '身份证实名认证清除',
  //   isLock: false,
  //   isChecked: false
  // },
  {
    key: 'resetIdForm',
    label: '重置实名认证',
    isLock: false,
    isChecked: false
  }, {
    key: 'loginCacheForm',
    label: '手机号登录缓存清除',
    isLock: false,
    isChecked: false
  },
  // {
  //   key: 'delThirdForm',
  //   label: '账号注销',
  //   isLock: false,
  //   isChecked: false
  // },
  {
    key: 'resetSmsForm',
    label: '重置短信获取次数',
    isLock: false,
    isChecked: false
  }, {
    key: 'changeIdForm',
    label: '修改实名认证信息',
    isLock: false,
    isChecked: false
  }];
  const currentTabList = ref(toolsList);
  const currentTab = ref('');
  const inputSearch = ref('');
  onMounted(() => {
    const data = localStorage.getItem('toolsList');
    if (data) {
      currentTabList.value = JSON.parse(data);
    }
    handleSort(currentTabList.value);
    currentTab.value = currentTabList.value[0].key;
    currentTabList.value[0].isChecked = true;
  });
  const handleChangeTab = key => {
    currentTab.value = key;
    currentTabList.value.forEach(item => {
      if (item.key === key) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
  };
  const handleSearch = e => {
    currentTabList.value = toolsList.filter(item => {
      return item.label.includes(e);
    });
    handleSort(currentTabList.value);
  };
  const handleOk = async () => {
    var _paramConfig$value$fi, _paramConfig$value$fi2;
    const currentParams = (_paramConfig$value$fi = paramConfig.value.find(item => item.key === currentTab.value)) === null || _paramConfig$value$fi === void 0 ? void 0 : _paramConfig$value$fi.params;
    const api = (_paramConfig$value$fi2 = paramConfig.value.find(item => item.key === currentTab.value)) === null || _paramConfig$value$fi2 === void 0 ? void 0 : _paramConfig$value$fi2.api;
    let flag = false;
    if (currentParams) {
      const params = {};
      currentParams.forEach(item => {
        params[item.prop] = item[item.prop];
        if (item.required && !item[item.prop]) {
          flag = true;
        }
      });
      if (flag) {
        return ElMessage.error('请填写必填参数');
      }
      console.log({
        currentParams
      });
      console.log({
        params
      });
      params.gid = gameInfo.value.Id;
      const res = await api(params);
      console.log({
        res
      });
    }
  };
  // 处理锁定排序，锁定的放到最前面
  const handleSort = list => {
    const lockList = list.filter(item => item.isLock);
    lockList.sort((a, b) => {
      return b.sort - a.sort;
    });
    const unlockList = list.filter(item => !item.isLock);
    currentTabList.value = [...lockList, ...unlockList];
  };
  const handleLock = key => {
    currentTabList.value.forEach(item => {
      if (item.key === key) {
        item.isLock = !item.isLock;
        item.sort = +new Date();
      }
    });
    handleSort(currentTabList.value);
    const toolsList = JSON.parse(localStorage.getItem('toolsList') || '[]');
    toolsList.forEach(item => {
      if (item.key === key) {
        item.isLock = !item.isLock;
        item.sort = +new Date();
      }
    });
    console.log({
      toolsList
    });
    localStorage.setItem('toolsList', JSON.stringify(toolsList));
  };
  return {
    inputSearch,
    toolsList,
    currentTab,
    paramConfig,
    handleChangeTab,
    handleOk,
    handleSearch,
    currentTabList,
    handleLock
  };
};